//该文件禁止修改，由后端错误码自动生成
export default {
	error: {
		"default": "操作发生错误，请稍后重试。",
		"100001": "验证错误",
		"100002": "账号密码错误",
		"100003": "实体不存在",
		"100004": "用户类型不一致",
		"100005": "远程API调用出错",
		"100006": "名称已经存在",
		"100007": "用户没有找到",
		"100008": "验证码超出限制",
		"100009": "获取token出错",
		"100010": "验证码错误",
		"100011": "验证修改Id不存在",
		"100012": "数据错误",
		"100013": "编码已经存在",
		"100014": "不被允许的访问",
		"100015": "登录已过期",
		"100016": "手机号不存在",
		"100017": "保存图片文件地址错误",
		"100018": "无效的时区",
		"100019": "公司负责人不能被删除,若需删除请先更换负责人。",
		"100020": "用户不是管理员",
		"100021": "参数是必须的",
		"100022": "无效时间戳",
		"100023": "无效的配置",
		"100024": "手机号已经存在",
		"100025": "角色已经存在",
		"100026": "pdf宽高比不符合",
		"100027": "国际区号与国家信息不匹配",
		"100028": "邮编不能为空",
		"100029": "详细地址不能为空",
		"100030": "原密码输入不正确",
		"100031": "电话号码格式错误",
		"100032": "当联系电话不为空时,区号所属国家必填",
		"100033": "公司标识不存在",
		"100034": "数据处理中",
		"100035": "签名签收类型不支持",
		"100036": "下载模板不是最新的",
		"100037": "不是PDF格式",
		"100401": "未授权错误",
		"100403": "无访问权限被拒绝",
		"100404": "方法未找到",
		"100429": "请求过快,请稍后重试",
		"100500": "服务器错误",
		"110001": "Email已经存在",
		"110002": "产品名称已经存在",
		"110003": "产品货号已经存在",
		"110004": "产品Id不存在",
		"110005": "此产品包装校验信息已存在",
		"110006": "用户已存在此币种账户",
		"110007": "余额账户不存在",
		"110008": "租户编号重复",
		"110009": "手机号已经存在",
		"110010": "运输特征空",
		"110011": "SE标签识别内容已存在",
		"110012": "海关信息不完整",
		"110013": "默认海关必填",
		"110014": "默认海关信息不完整",
		"110015": "销售来源名称已存在",
		"110016": "账单已经被处理",
		"110017": "已经存在已支付的账单",
		"110018": "余额账户不存在",
		"110019": "联系地址名称存在",
		"110020": "资金账户异常",
		"110021": "付款流水号已入账或在充值流程中，不可重复提交",
		"110022": "账户图形验证码验证失败",
		"110023": "账户已经绑定租户",
		"110024": "账户还未绑定租户",
		"110025": "透支余额不足",
		"110026": "充值单已处理不可重复操作",
		"110027": "充值状态异常",
		"110028": "账号审核状态不是未通过",
		"110029": "币种不存在",
		"110030": "没有对应的汇率",
		"110031": "请上传SE标签",
		"110032": "付款异常",
		"110033": "余额不足",
		"110034": "透支额度不足",
		"110035": "不可重复支付",
		"110036": "不可重复退款",
		"110037": "退款失败",
		"110038": "只支持中国手机号码",
		"110039": "不存在该手机号的账号",
		"110040": "充值金额必须大于0",
		"110041": "没有ShopId",
		"110042": "没有收款账户信息，请联系管理员",
		"110043": "当前币种已经有处理中的充值单",
		"110044": "充值金额最大一百万",
		"110045": "透支限额未更改",
		"110046": "逾期未付",
		"110047": "传入的标签识别码重复",
		"110048": "当前账户等级下还有公司",
		"110049": "时间间隔最多31天",
		"110050": "产品未找到",
		"110051": "公司已经被冻结",
		"110052": "退款金额必须大于0",
		"110053": "公司名称已经存在",
		"110054": "公司不存在",
		"110055": "联系电话不匹配",
		"110056": "通用联系人数据处理中",
		"110057": "产品货号不符合规则",
		"110058": "包含电池电池信息必填",
		"110059": "运输特征信息错误",
		"110060": "电池类型不存在",
		"110061": "电池成分不存在",
		"110062": "角色不存在",
		"110063": "当前充值状态不可提交",
		"110064": "当前充值状态不可取消",
		"110065": "不是有效的Excel文件",
		"110066": "当前产品已被使用不允许删除",
		"110067": "产品导入出错",
		"110068": "营业执照代码已存在",
		"110069": "常用联系地址不存在",
		"120001": "仓库名称已存在",
		"120002": "库位类型名称已存在",
		"120003": "区域名称已存在",
		"120004": "区域位置序号已存在",
		"120005": "区域下存在子区域或者区域下有库位",
		"120006": "出库计划名称已存在",
		"120007": "入库计划名称已存在",
		"120008": "仓库编号已经存在",
		"120009": "费用代码已经存在",
		"120010": "仓库不存在",
		"120011": "调拨计划不存在",
		"120012": "箱信息不存在",
		"120013": "设置数量错误",
		"120014": "设置数量超过最大限制",
		"120015": "配送订单已全部生成波次任务",
		"120016": "当前库位下存在产品",
		"120017": "当调查状态为完成调查时，不能重复更新",
		"120018": "超过当前库位的允许的最大值",
		"120019": "当前库位已经存放其他计划的产品",
		"120020": "当前库位未绑定仓库区域",
		"120021": "当前库位不属于入库区",
		"120022": "当前库位不属于出库区",
		"120023": "当前库位不属于交付区",
		"120024": "仓库为配送仓时，标签处理方式必填",
		"120025": "当前入库状态不允许修改信息",
		"120026": "当前入库状态不能填写跟踪编号",
		"120027": "当前入库状态不能确认计划",
		"120028": "当前入库状态不能发运",
		"120029": "当前入库状态不能操作异常状态",
		"120030": "当前入库状态不能入库",
		"120031": "存储仓入库错误状态只能为接收超时",
		"120032": "入库错误状态为超时时，客户操作只能为漏发或者请求调查",
		"120033": "跟踪编号不正确",
		"120034": "产品入库规格照片/核验照片必须传照片",
		"120035": "当为数量缺失入库时缺失入库照片必须传照片",
		"120036": "当为错误产品入库时缺错误产品照片必须传照片",
		"120037": "当为正常入库时入库数量应大于等于装箱数量",
		"120038": "当出库类型为亚马逊时,亚马逊传输编号和轨迹追踪号不能为空",
		"120039": "当出库类型为调拨/转入本地仓时,仓库信息不能为空",
		"120040": "当出库类型为其他地址时,接收者信息不能为空",
		"120041": "当出库类型不为转入本地仓,打托服务必填",
		"120042": "当出库类型为转入本地仓时,SE标签处理必填",
		"120043": "当出库类型为亚马逊/其他地址时,外箱标签必填",
		"120044": "当出库类型不为转入本地仓时，支付方式必填",
		"120045": "当交付方式为自提时，自提码必填",
		"120046": "当交付方式为赛易物流时，物流信息必填",
		"120047": "当前出库状态不能修改信息",
		"120048": "当前出库状态不能确认",
		"120049": "当前出库状态不能贴标",
		"120050": "当前出库状态不能扫描",
		"120051": "当前出库状态不能重置扫描",
		"120052": "当前出库状态不能出库",
		"120053": "当前出库状态不能交付",
		"120054": "当前出库计划存在相同的箱号",
		"120055": "还存在未扫描产品不能出库",
		"120056": "交付类型为自提时，请输入正确的用户自提码",
		"120057": "当前还存在未处理的产品不能出库",
		"120058": "波次任务不存在",
		"120059": "波次任务已经被人领取",
		"120060": "箱号已被其他计划使用",
		"120061": "该箱不属于当前仓库",
		"120062": "波次任务该状态不能放弃",
		"120063": "波次任务还没人认领",
		"120064": "拣货箱信息不存在",
		"120065": "请绑定类型为拣货箱的库位",
		"120066": "波次任务详细信息不存在",
		"120067": "仓库库存不足",
		"120068": "位置错误",
		"120069": "目标错误",
		"120070": "产品拣货已完成",
		"120071": "库存库位产品不存在",
		"120072": "拣货任务不存在",
		"120073": "当前出库状态不能领取任务或者该出库任务已被领取",
		"120074": "该出库计划的任务还未领取",
		"120075": "拣货任务未完成",
		"120076": "当前出库状态不能重新入库",
		"120077": "拣货箱不可用,已绑定波次任务",
		"120078": "波次任务未绑定分拣箱",
		"120079": "拣货箱无波次任务",
		"120080": "分拣盒数量不对",
		"120081": "分拣盒有订单信息，不能使用",
		"120082": "分拣任务不存在",
		"120083": "产品已完成分拣",
		"120084": "拣货箱产品不够",
		"120085": "分拣任务未完成",
		"120086": "分拣盒区域类型错误",
		"120087": "包装出库任务不存在",
		"120088": "产品目标错误",
		"120089": "产品已完成扫描",
		"120090": "包装出库任务产品校验未完成",
		"120091": "目标库位不是移库箱",
		"120092": "库位没有区域",
		"120093": "移动产品量超过任务需要量",
		"120094": "移动产品没有移库任务",
		"120095": "产品库存不足",
		"120096": "产品没有库存",
		"120097": "仓库下没有该库位",
		"120098": "仓库Id必填",
		"120099": "序号超过限制",
		"120100": "仓库类型相同",
		"120101": "调拨计划不存在",
		"120102": "配送仓信息不存在",
		"120103": "存储仓库信息不存在",
		"120104": "配送计划信息不存在",
		"120105": "配送计划详细不存在",
		"120106": "配送计划箱信息不存在",
		"120107": "产品输入参数错误",
		"120108": "库位信息错误",
		"120109": "仓库类型错误",
		"120110": "库位区域类型不可用(未启用)",
		"120111": "产品信息不存在",
		"120112": "包装信息不存在",
		"120113": "订单状态已改变",
		"120114": "仓库产品不存在",
		"120115": "设置数量超过最大限制",
		"120116": "计划装箱数不能为空",
		"120117": "该入库计划存在相同的产品",
		"120118": "出库工作台不存在",
		"120119": "入库错误状态为错误产品时，客户操作不能漏发或者请求调查",
		"120120": "客户操作为更换货号时,需要选择产品",
		"120121": "客户操作为请求调查时,调查内容必填",
		"120122": "请选择入库计划",
		"120123": "入库计划编号不存在",
		"120124": "当前操作者的PIN码不正确",
		"120125": "通用入库规格错误",
		"120126": "无效的仓库Id",
		"120127": "收货地址不能为空",
		"120128": "该计划无核验异常，无须重新计算",
		"120129": "该计划Sesku错误",
		"120130": "存放库位不应包含操作台库位",
		"120131": "区域填写错误",
		"120132": "该库位/用户已绑定其他闸机",
		"120133": "计划箱号无效",
		"120134": "运输计划不存在或状态已改变",
		"120135": "运输计划详细不存在",
		"120136": "运输计划箱未完成全部扫描",
		"120137": "运输计划箱未完成全部收货",
		"120138": "运输计划箱仓库箱信息不存在",
		"120139": "运输计划箱信息不存在",
		"120140": "运输计划箱核验长宽高必填",
		"120141": "运输计划箱核验超过误差阈值",
		"120142": "数量缺失入库时，接收数量应小于装箱箱数并且大于0",
		"120143": "运输计划物流中心仓库未找到",
		"120144": "运输计划详细不存在或状态已改变",
		"120145": "该绑定区域下已经存在该位置编码",
		"120146": "运输计划物流信息不存在",
		"120147": "当前出库计划不属于当前操作者",
		"120148": "存储费配置不存在",
		"120149": "仓库/地址不支持赛易物流",
		"120150": "出库时目标仓库信息和出库仓库不能相同",
		"120151": "拣货箱错误，波次任务已绑定拣货箱",
		"120152": "箱的尺寸（长/宽/高）超过托盘最大的尺寸（长/宽/高）",
		"120153": "选择的仓库没有地址无法创建运输计划",
		"120154": "包装出库任务已绑定工作台",
		"120155": "员工手机号已经存在",
		"120156": "运输计划当前状态不可编辑",
		"120157": "新库位编码不正常",
		"120158": "配送仓地址不存在",
		"120159": "配送仓送货地址不存在",
		"120160": "选择的仓库没有地址无法创建运输计划",
		"120161": "运输计划收货地址不完整",
		"120162": "运输计划已选择上门揽件 上门联系人、联系电话必填",
		"120163": "外包装体积或容重不足",
		"120164": "运输计划名称已存在",
		"120165": "运输计划名称一样,不需要修改",
		"120166": "运输计划没有审核的费用,不可支付",
		"120167": "运输计划物流体积或者重量超标",
		"120168": "运输计划发货地址不存在",
		"120169": "揽收网点不存在不存在",
		"120170": "运输计划海关只能有一种货币报价",
		"120171": "运输计划不存在",
		"120172": "运输计划已支付",
		"120173": "闸机入参token或者机器码错误",
		"120174": "闸机所在仓库信息未找到",
		"120175": "归集中或者出库中不能绑定操作台",
		"120176": "运输计划揽件任务不存在",
		"120177": "雇员信息未找到",
		"120178": "运输计划揽件状态已改变",
		"120179": "运输计划揽件到达时间必填",
		"120180": "运输计划揽件未设定预计到达时间",
		"120181": "运输计划揽件预计到达时间不能小于当前时间",
		"120182": "运输计划上门揽件未完成",
		"120183": "运输计划已退款",
		"120184": "运输计划未支付,不可退款",
		"120185": "入库/出库创建的运输计划不可删除",
		"120186": "该库位下产品库存不足",
		"120187": "配送订单不存在",
		"120188": "当前库位不属于调拨工作台",
		"120189": "当前库位不属于归集箱",
		"120190": "当前归集箱不属于该调拨工作台",
		"120191": "调拨操作台未创建",
		"120192": "调拨计划装箱数量错误",
		"120193": "该箱还未打印箱标",
		"120194": "计划存在未装箱完成的箱",
		"120195": "归集任务不存在",
		"120196": "归集任务详细不存在",
		"120197": "归集任务已被认领",
		"120198": "归集任务未认领",
		"120199": "移库箱移入不支持的区域类型",
		"120200": "移库箱移出不支持的区域类型",
		"120201": "归集任务已完成",
		"120202": "归集箱已绑定其它归集任务",
		"120203": "归集箱未找到",
		"120204": "归集箱区域类型错误",
		"120205": "归集任务未绑定归集箱",
		"120206": "归集任务库位信息不存在",
		"120207": "该产品已完成归集",
		"120208": "调拨至存储计划包装体积太小",
		"120209": "归集任务未完成",
		"120210": "部门下还有子部门",
		"120211": "部门下还有员工",
		"120212": "支付记录不存在",
		"120213": "收发地不支持物流",
		"120214": "运输计划用户已支付",
		"120215": "当前库位产品不存在",
		"120216": "运输计划没添加产品",
		"120217": "运输计划已发货",
		"120218": "运输计划已送达",
		"120219": "运输计划已取消",
		"120220": "运输计划物流单号参数必填",
		"120221": "运输计划物流单号参数错误",
		"120222": "运输计划上门揽件限制最低重量",
		"120223": "当出库类型为美客多时,SELLER ID 和 入仓编号不能为空",
		"120224": "运输计划箱尺寸或重量过大无法计算物流费用",
		"120225": "整柜预约未找到",
		"120226": "运输计划当前不可删除",
		"120227": "入库计划未找到",
		"120228": "出库计划未找到",
		"120229": "库位未找到",
		"120230": "区域未找到",
		"120231": "摧毁任务未找到",
		"120232": "闸机未找到",
		"120233": "存储费用未找到",
		"120234": "操作费用未找到",
		"120235": "包装费用未找到",
		"120236": "运输计划已处理，不能取消入库计划",
		"120237": "运输计划已处理，不能取消出库计划",
		"120238": "物流计费方式为计费重量，核算计费总体积参数只能为空",
		"120239": "物流计费方式为计费体积，核算计费总重量参数只能为空",
		"120240": "当交付方式为自提时，自提类型必选",
		"120241": "当自提类型为卡车时，自提码必填",
		"120242": "运单客户单号已经存在",
		"120243": "不支持DestinationType",
		"120244": "当目的地类型为其他地址时，收件人信息必填",
		"120245": "当目的地类型为美客多时，仓库Id必填",
		"120246": "当目的地类型为FBA时，仓库Id必填",
		"120247": "当目的地类型为赛意仓库时，仓库Id必填",
		"120248": "运单未找到",
		"120249": "运单当前状态不能选择供应商物流方案",
		"120250": "运单当前状态不能取消",
		"120251": "运单当前状态不能预报",
		"120252": "运单当前状态不能待上网",
		"120253": "运单当前状态不能待签收",
		"120254": "运单当前状态不能已签收",
		"120255": "不支持当前运单操作类型",
		"120256": "超过最大可用的出库箱数",
		"120257": "退货详细不可空",
		"120258": "选择功能质检必须同时选择外观质检",
		"120259": "退货包裹编号已存在",
		"120260": "退货计划不存在",
		"120261": "退货计划状态已改变",
		"120262": "运输计划报关方式图片必传",
		"120263": "工作台编码错误，请绑定入库区",
		"120264": "退货计划已完成",
		"120265": "退货计划已取消",
		"120266": "退货计划产品可识别标签未找到",
		"120267": "费用配置不存在",
		"120268": "退货计划入库未完成",
		"120269": "退货计划产品未入库",
		"120270": "退货计划产品核验长宽高重量必填",
		"120271": "退货计划产品未核验",
		"120272": "退货计划已绑定其它工作台",
		"120273": "退货计划当前状态不可退款",
		"120274": "退货计划标签费未设定",
		"120275": "退货计划退货服务费未设定",
		"120276": "退货计划标准质检费未设定",
		"120277": "退货计划销毁服务费未设定",
		"120278": "退货计划功能性质检附加费未设定",
		"120279": "当前状态，不能修改",
		"120280": "当前退货计划产品FNSKU不可重复",
		"120281": "退货计划未选择标准质检，不能销毁",
		"120282": "退货计划只能销毁不合格产品",
		"120283": "仓库已经被停用",
		"120284": "选择质检后仓库必须配置销毁服务费",
		"120285": "当前运输计划不可取消",
		"120286": "配送订单箱标签费未设定",
		"120287": "出库操作费未设定",
		"120288": "配送订单操作费未设定",
		"120289": "配送订单包装材料必选",
		"120290": "配送订单产品数量必填",
		"120291": "调拨计划暂不支持",
		"120292": "当前仓库暂不支持出库操作",
		"120293": "当前渠道不支持该地址类型",
		"120294": "其他库位库存不足",
		"120295": "拣货未完成",
		"120296": "分拣盒还未启用",
		"120297": "仓库库位不可用",
		"120298": "包装出库任务订单状态不匹配",
		"120299": "选择人工识别仓库必须配置人工识别费用",
		"120300": "配送订单包装材料体积或容重不足",
		"120301": "选择人工识别仓库必须配置人工识别费用",
		"120302": "完成归集任务获取锁超时",
		"120303": "配送订单取消订单获取锁超时",
		"120304": "当前仓库自提费用暂未配置",
		"120305": "配送订单第三方面单文件必传",
		"120306": "配送订单第三面单必填",
		"120307": "包含非当前用户产品",
		"120308": "当前箱不属于该用户",
		"120309": "当前仓库出库操作费用暂未配置",
		"120310": "当前仓库标签操作费用暂未配置",
		"120311": "当前仓库打托操作费用暂未配置",
		"120312": "当前仓库包装费用暂未配置",
		"120313": "第三方面单文件格式不正确",
		"120314": "退货计划只允许修改一次",
		"120315": "退货包裹号不符合规则",
		"120316": "拣货已完成",
		"120317": "分拣任务已完成",
		"120318": "当前拣货任务状态不允许挂起",
		"120319": "波次任务生成订单获取锁错误",
		"120320": "包装出库已发货",
		"120321": "面单未打印或者物流跟踪号未填写",
		"120322": "第三方面单不允许设置线下物流面单",
		"120323": "请选择派送方式",
		"120324": "配送订单已取消",
		"120325": "运单处理中",
		"120326": "未找到该计划对应的箱",
		"120327": "入库计划处理中",
		"120328": "出库计划处理中",
		"120329": "调拨计划处理中",
		"120330": "闸机处理中",
		"120331": "仓库库位处理中",
		"120332": "仓库库位类型处理中",
		"120333": "仓库区域处理中",
		"120334": "仓库库位任务处理中",
		"120335": "入库请求调查处理中",
		"120336": "箱信息处理中",
		"120337": "配送订单处理中",
		"120338": "支付失败不创建退货计划",
		"120339": "仓库对公司的折扣配置已经存在",
		"120340": "运输计划该状态不能收货",
		"120341": "运输计划未开始收货",
		"120342": "运输计划当前状态不可退款",
		"120343": "运输计划添加到付异常",
		"120344": "运输计划不支持物流到付",
		"120345": "该状态不允许收货",
		"120346": "运输计划箱已取消",
		"120437": "待审核或者待支付状态才允许更新箱尺寸",
		"120439": "分拣未完成",
		"120440": "已申请退货",
		"120441": "配送订单用户未申请退货",
		"120442": "包装袋仅支持装一个产品",
		"120443": "配送订单包装材料体积或容重不足",
		"120444": "派送方式不支持",
		"120445": "物流方式不支持",
		"120446": "出库类型不支持",
		"120447": "目的地类型不支持",
		"120448": "运输特征不支持",
		"120449": "调拨标签费未设定",
		"120450": "调拨箱标费未设定",
		"120451": "调拨操作费未设定",
		"120452": "归集费未设定",
		"120453": "卸货费未设置",
		"120454": "爽约金未设置",
		"120455": "请求调查证明材料必须的",
		"120456": "请求调查证明材料最多3张",
		"120457": "退货来源不支持",
		"120458": "可识别码类型错误",
		"120459": "仓库退货存储区域处理中",
		"120460": "当前退货暂存区域存在包裹",
		"120461": "当前退货暂存区域存在子区域",
		"120462": "退货暂存区未找到",
		"120463": "退货暂存区不能被使用",
		"120464": "暂存费未设定",
		"120465": "暂存信息不存在",
		"120466": "超过15日无匹配的退货信息才能被销毁",
		"120468": "订单不存在或状态已改变",
		"120469": "无匹配的退货暂存才能移库",
		"120470": "有退货计划不能被销毁",
		"120471": "退货工作台不存在",
		"120472": "已匹配暂存区包裹，不可修改",
		"120473": "当前包裹已销毁",
		"120474": "退货记录不存在",
		"120475": "运单取消异常",
		"120476": "该状态不允许退款或赔付",
		"120477": "订已完成退款",
		"120478": "已完成赔付",
		"120479": "操作数量必须大于等于1",
		"120480": "卡车费用不存在",
		"120481": "报价类型不存在",
		"120482": "服务等级不存在",
		"120483": "附加选项不存在",
		"120484": "当报价类型为WG时服务等级必须的",
		"120485": "运输计划处理中",
		"120486": "不支持ExceptionInvestigationResult",
		"120487": "区域不存在",
		"120489": "入库计划已处理不能取消",
		"120490": "调拨计划不存在或者状态已改变",
		"120491": "调拨计划处理中",
		"120492": "订单允许最大数量",
		"120493": "包装材料不存在",
		"120494": "包装材料不可用",
		"120495": "物流方案不支持",
		"120496": "合并包装费未设置",
		"120497": "合并包装费不支持",
		"120498": "包装材料类型不支持",
		"120499": "电话所属国家不存在",
		"120500": "退货包裹备用识别信息1已存在",
		"120501": "退货包裹备用识别信息2已存在",
		"120502": "对账信息不存在",
		"120503": "货件号不存在",
		"120504": "中转仓入库计划不存在",
		"120505": "货件信息不存在",
		"120506": "货件明细参数错误",
		"120507": "货件箱信息不存在",
		"120508": "货件箱状态已改变",
		"120509": "货件箱长宽高重量必填",
		"120510": "入库计划状态已改变",
		"120511": "需要上传预约文件",
		"120512": "货件Id不存在",
		"120513": "货件不可出库",
		"120514": "货件状态已改变",
		"120515": "中转仓入库预约日期必须为48小时后",
		"120516": "中转仓入库同一计划存在相同的可扫描箱号",
		"120517": "中转仓入库货件名称已存在",
		"120518": "中转仓入库审核状态错误",
		"120519": "当前状态不允许当前操作",
		"120520": "中转仓入库货件箱核验图片必填",
		"120521": "中转仓入库货件已经被锁定",
		"120522": "没有有效的货件箱",
		"120523": "联系人不存在",
		"120524": "当前出库计划不可取消",
		"120525": "预约日期或时间不可空",
		"120526": "必须选择48小时以后",
		"120527": "出库计划不存在",
		"120528": "出库计划目的地址不完整",
		"120529": "出库计划发货地址不存在",
		"120530": "流中心仓库未找到",
		"120531": "设置货件子状态错误",
		"120532": "入库预约信息未审核",
		"120533": "入库预约信息收货已完成",
		"120534": "入库预约信息取消或者失败",
		"120535": "物流中心仓库未找到",
		"120537": "货件当前状态不可更改预约文件",
		"120538": "货件未锁定",
		"120539": "当前库位不属于存储区",
		"120540": "当前库位区域错误",
		"120541": "上架费",
		"120542": "操作费未配置",
		"120543": "合并包装费未设置",
		"120544": "基础卸载费未设置",
		"120545": "超规卸载费未设置",
		"120546": "超大件卸货附加费未设置",
		"120547": "集装箱类型错误",
		"120548": "该计划不需要支付",
		"120549": "操作费配置关联已存在",
		"120550": "箱尺寸过大，暂不支持",
		"120551": "库存调整处理中",
		"120552": "调整类型错误",
		"120553": "退货暂存销毁费未设定",
		"120554": "运输计划没添加箱",
		"120555": "暂存包裹已确认销毁",
		"120556": "存在相同的箱信息",
		"120557": "箱信息不存在",
		"120558": "箱状态已改变",
		"120559": "换标任务不存在",
		"120560": "换标任务操作步骤已改变",
		"120561": "装箱费未设置",
		"120562": "清点费未设置",
		"120563": "箱处于待服务状态，暂不能出库",
		"120564": "换标服务暂不支持",
		"120565": "换标详细列表必选",
		"120566": "货件箱状态不可操作",
		"120567": "换标详细不存在",
		"120568": "已分配操作台",
		"120569": "箱产品信息不可空",
		"120570": "产品图片最大两张",
		"120571": "换标服务箱状态已改变",
		"120572": "换标箱任务未完成",
		"120573": "换标任务箱产品关系不存在",
		"120574": "换标任务贴标操作已完成",
		"120575": "同一箱可识别号不能重复",
		"120576": "箱产品已经存在",
		"120577": "库位不属于服务区",
		"120578": "标签任务状态不是已下单状态",
		"120579": "标签任务未设置标签操作台",
		"120580": "箱标和产品标不可都空",
		"120582": "当前状态不可取消",
		"120583": "中转仓换标费用异常",
		"120584": "每一箱产品不可重复",
		"120585": "可识别信息系统已存在",
		"120586": "箱所在区域不允许操作",
		"120587": "当前货件被占用或正在服务",
		"120588": "箱未归集",
		"120589": "换标箱已取消",
		"120590": "货件已锁定",
		"120591": "箱信息不可用",
		"120592": "箱/产品未核验",
		"120593": "创建箱异常",
		"120594": "当前计划存在相同的自定义箱号",
		"120595": "当出库计划类型为调拨时，箱必须包含产品",
		"120596": "运输计划详细产品不存在",
		"120597": "当前计划下箱或者产品存在待处理的异常",
		"120598": "箱长宽高重数据不正确",
		"120599": "自提码错误",
		"120600": "运输计划箱必须有产品",
		"120601": "箱不可用，可能原因：库位不在存储区和入库区;箱状态不为已入库;被其他计划锁定",
		"120602": "该渠道不支持该签名签收类型",
		"120603": "暂存区不存在",
		"120604": "暂存区存在货物",
		"120605": "暂存区存在子区域",
		"120606": "当前计划下箱不存在待处理异常",
		"120607": "暂存区不存在或不能被使用",
		"120608": "包裹接收中",
		"120609": "入库异常暂存记录不存在",
		"120610": "归集箱未交付",
		"120611": "调拨计划状态已改变",
		"120612": "归集箱已交付",
		"120613": "当前调拨操作台不属于当前调拨计划",
		"120614": "操作失败：1.箱状态错误 2.被其他人锁定",
		"120615": "操作失败：1.箱状态错误 2.已经被解锁 3.当前锁定者不是您",
		"120616": "计划已完成装箱",
		"120617": "归集箱剩余数量不足",
		"120618": "装箱数不能大于计划数量",
		"120619": "装箱未完成",
		"120620": "收件人不可修改",
		"120621": "调拨操作台位置错误",
		"120622": "归集箱未完成装箱不可用",
		"120623": "舱位不存在",
		"120624": "运输已结束",
		"120625": "海关放行日期空",
		"120626": "操作日期空",
		"120628": "操作日期不能晚于计划离港时间",
		"120629": "计划离岗时间不能晚于海关放行时间",
		"120630": "离港日期空",
		"120631": "离港日期不能晚于操作日期",
		"120632": "离港日期不能晚于ETA时间",
		"120633": "计划到港ETA不可空",
		"120634": "ETA不能晚于离港日期",
		"120635": "到港日期不能晚于ETA",
		"120636": "到港日期空",
		"120637": "开始清关日期空",
		"120638": "开始清关日期不能晚于到港日期",
		"120639": "清关完成日期空",
		"120640": "到达处理中心日期空",
		"120641": "清关完成日期不能晚于开始清关日期",
		"120642": "到达处理中心日期不能晚于清关完成日期",
		"120643": "到达赛易仓库日期不能晚于清关完成日期",
		"120644": "处理中心接收日期空",
		"120645": "舱位没有关联的运单",
		"120646": "海运柜号必填",
		"120647": "运输方案已分配舱位",
		"120648": "没有运输清单",
		"120649": "海关放行日期不能小于报关日期",
		"120650": "ETA不可空",
		"120651": "港口名称已存在",
		"120652": "港口代码已存在",
		"120653": "航司名称已存在",
		"120654": "航司代码已存在",
		"120655": "港口已使用不可删除",
		"120656": "航司已使用不可删除",
		"120657": "不是快速发货任务",
		"120658": "快速发货已完成或任务不存在",
		"120659": "费用已经变更,请刷新后再支付",
		"120660": "不支持上门揽件",
		"120661": "序号只能是数字",
		"120662": "自定义箱标数字、字母、\"-\"、\"_\"、\"\\\"、\"/\"组合的35位字符",
		"120663": "当前状态不可删除",
		"120664": "操作台不存在",
		"120665": "当出库类型为亚马逊/美客多时,外箱标签必填",
		"130001": "名称已经存在",
		"130002": "货币代码已经存在",
		"130003": "单次上传超过20个文件",
		"130004": "单个文件大于5MB",
		"130005": "标签条码解析失败",
		"130006": "省份不存在",
		"130007": "国家不存在",
		"130008": "城市不存在",
		"130009": "区县不存在",
		"130010": "无效的币种",
		"130011": "解析条码格式不支持",
		"130012": "费用配置币种已存在",
		"130013": "费用配置不存在",
		"130014": "上传文件格式不支持",
		"130015": "上传文件最大50MB",
		"130016": "Base64文件格式错误",
		"130017": "解析条码失败",
		"130018": "电话区号所属国家不存在",
		"130019": "每次最多上传100条",
		"130020": "平台FBA/MCD地址不存在",
		"130021": "单个文件大于50MB",
		"130022": "文件页码不能大于箱数",
		"130023": "PDF文件只允许一页",
		"140000": "物流方案未找到",
		"140001": "物流方案地址国家错误",
		"140002": "物流方案地址省/州错误",
		"140003": "物流方案地址城市错误",
		"140004": "物流方案地址区县错误",
		"140005": "费用模板未找到",
		"140006": "费用模板配置项不能为空",
		"140007": "起始重量不能小于0",
		"140008": "终止重量需要大于起始重量",
		"140009": "首重费用不能小于0",
		"140010": "续重单位必须大于0",
		"140011": "续重单价不能小于0",
		"140012": "重量区间应该为连续的",
		"140013": "重量不能小于0",
		"140014": "费用不能小于0",
		"140015": "计费方式错误",
		"140016": "数量不能小于0",
		"140017": "每页数量必须大于0",
		"140018": "重量类型错误",
		"140019": "起始长度不能小于0",
		"140020": "终止长度需要大于起始长度",
		"140021": "附加费计算方式错误",
		"140022": "间隔必须大于0",
		"140023": "附加费配置未找到",
		"140024": "附加费配置数据不完整",
		"140025": "附加费配置数据错误",
		"140026": "区域未找到",
		"140027": "国家数据未找到",
		"140028": "省/州数据未找到",
		"140029": "城市数据未找到",
		"140030": "区县数据未找到",
		"140031": "邮编前缀不能为空",
		"140032": "区域名称为必填",
		"140033": "国家区域不能为空",
		"140034": "省/州区域不能为空",
		"140035": "城市区域不能为空",
		"140036": "区县区域不能为空",
		"140037": "邮编区域不能为空",
		"140038": "费用名称不能为空",
		"140039": "费用名称太长",
		"140040": "最小天数不能小于0",
		"140041": "最大天数必须大于0和最小天数",
		"140042": "折扣不能小于0",
		"140043": "燃油附加费不能小于0",
		"140044": "体积重参数必须大于0",
		"140045": "分泡系数不能小于0",
		"140046": "进位重量间隔必须大于0",
		"140047": "最小重量不能小于0",
		"140048": "最大重量必须大于最小重量",
		"140049": "最小体积不能小于0",
		"140050": "最大重量必须大于最小重量",
		"140051": "最小计费重量必须大于0",
		"140052": "名称不能为空",
		"140053": "代码不能为空",
		"140054": "仓库不能为空",
		"140055": "包装信息不能为空",
		"140056": "产品信息不能为空",
		"140057": "目标地址不能为空",
		"140058": "重量仅支持两位小数",
		"140059": "长度仅支持两位小数",
		"140060": "物流费错误。(可能原因:尺寸超规，渠道不支持等)",
		"140061": "物流账户名称不能为空",
		"140062": "物流账户类型不支持",
		"140063": "物流账户配置不能为空",
		"140064": "来源地址不能为空",
		"140065": "DHL和USPS暂不支持",
		"140066": "账户信息不存在",
		"140067": "创建物流面单失败",
		"140068": "暂不支持国际运输",
		"140069": "Fedex 长和外围超长 长+（宽+高）*2 应小于等于 330CM",
		"140070": "Fedex 重量应小于等于68KG",
		"140071": "获取物流轨迹失败",
		"140072": "区域标记是必填的",
		"140073": "区域标记错误",
		"140074": "特殊区域未找到",
		"140075": "物流方案的国家与物流中心的国家不同",
		"140076": "账户对应的JSON数据不正确",
		"140077": "物流账户中物流渠道不能为空",
		"140078": "物流账户中交付方式不能为空",
		"140079": "内置渠道未找到",
		"140080": "物流方案禁运配置地址类型错误",
		"140081": "物流计算方式错误",
		"140082": "折扣必须大于0%和小于等于100%",
		"140083": "用户等级未找到",
		"140084": "该用户等级的折扣已经存在",
		"140085": "折扣信息未找到",
		"140086": "物流方案限运配置地址类型错误",
		"140087": "物流方案限运配置未找到",
		"140088": "物流方案禁运配置未找到",
		"140089": "佣金比例不能小于0",
		"140090": "物流方案vat必填",
		"140091": "折扣必须大于0%和小于等于1000%",
		"140092": "禁运配置处理中",
		"140093": "限运配置处理中",
		"140094": "计费方式错误",
		"140095": "接口账户必填",
		"140096": "签收费类型重复",
		"140097": "当没有签名费配置时，不能启用签名签收费",
		"140098": "物流账期配置不存在或未启用",
		"140099": "物流账期配置不支持商业快递物流渠道",
		"150000": "供应商方案未找到",
		"150001": "供应商方案地址国家错误",
		"150002": "供应商方案地址省/州错误",
		"150003": "供应商方案地址城市错误",
		"150004": "供应商方案地址区县错误",
		"150005": "费用模板未找到",
		"150006": "费用模板配置项不能为空",
		"150007": "起始重量不能小于0",
		"150008": "终止重量需要大于起始重量",
		"150009": "首重费用不能小于0",
		"150010": "续重单位必须大于0",
		"150011": "续重单价不能小于0",
		"150012": "重量区间应该为连续的",
		"150013": "重量不能小于0",
		"150014": "费用不能小于0",
		"150015": "计费方式错误",
		"150016": "数量不能小于0",
		"150017": "每页数量必须大于0",
		"150018": "重量类型错误",
		"150019": "起始长度不能小于0",
		"150020": "终止长度需要大于起始长度",
		"150021": "附加费计算方式错误",
		"150022": "间隔必须大于0",
		"150023": "附加费配置未找到",
		"150024": "附加费配置数据不完整",
		"150025": "附加费配置数据错误",
		"150026": "区域未找到",
		"150027": "国家数据未找到",
		"150028": "省/州数据未找到",
		"150029": "城市数据未找到",
		"150030": "区县数据未找到",
		"150031": "邮编前缀不能为空",
		"150032": "区域名称为必填",
		"150033": "国家区域不能为空",
		"150034": "省/州区域不能为空",
		"150035": "城市区域不能为空",
		"150036": "区县区域不能为空",
		"150037": "邮编区域不能为空",
		"150038": "费用名称不能为空",
		"150039": "费用名称太长",
		"150040": "最小天数不能小于0",
		"150041": "最大天数必须大于0和最小天数",
		"150042": "折扣不能小于0",
		"150043": "燃油附加费不能小于0",
		"150044": "体积重参数必须大于0",
		"150045": "分泡系数不能小于0",
		"150046": "进位重量间隔必须大于0",
		"150047": "最小重量不能小于0",
		"150048": "最大重量必须大于最小重量",
		"150049": "最小体积不能小于0",
		"150050": "最大体积必须大于最小体积",
		"150051": "最小计费重量必须大于0",
		"150052": "名称不能为空",
		"150053": "代码不能为空",
		"150054": "仓库不能为空",
		"150055": "包装信息不能为空",
		"150056": "产品信息不能为空",
		"150057": "目标地址不能为空",
		"150058": "重量仅支持两位小数",
		"150059": "长度仅支持两位小数",
		"150060": "供应商费错误。(可能原因:尺寸超规，渠道不支持等)",
		"150061": "Api账户名称不能为空",
		"150062": "Api代码类型不支持",
		"150063": "Api账户配置不能为空",
		"150064": "来源地址不能为空",
		"150065": "DHL和USPS暂不支持",
		"150066": "账户信息不存在",
		"150067": "创建供应商面单失败",
		"150068": "暂不支持国际运输",
		"150069": "Fedex 长和外围超长 长+（宽+高）*2 应小于等于 330CM",
		"150070": "Fedex 重量应小于等于68KG",
		"150071": "获取供应商轨迹失败",
		"150072": "区域标记是必填的",
		"150073": "区域标记错误",
		"150074": "特殊区域未找到",
		"150075": "供应商方案的国家与供应商中心的国家不同",
		"150076": "账户对应的JSON数据不正确",
		"150077": "供应商账户中供应商渠道不能为空",
		"150078": "供应商账户中交付方式不能为空",
		"150079": "内置渠道未找到",
		"150080": "供应商方案禁运配置地址类型错误",
		"150081": "供应商计算方式错误",
		"150082": "折扣必须大于0%和小于等于100%",
		"150083": "用户等级未找到",
		"150084": "该用户等级的折扣已经存在",
		"150085": "折扣信息未找到",
		"150086": "供应商方案限运配置地址类型错误",
		"150087": "供应商方案限运配置未找到",
		"150088": "供应商方案禁运配置未找到",
		"150089": "不支持API分类",
		"150090": "API接口管理已经存在该名称",
		"150091": "API接口管理未找到",
		"150092": "API配置已经存在该名称",
		"150093": "API配置未找到",
		"150094": "易抵达物流类型不支持",
		"150095": "易抵达付税金类型不支持",
		"150096": "Fedex/Ups/Idida/Elvish/Areship/Frayun/Hyeus/ExpressMX/JTExpress/Truck 下 收货渠道代码不能为空",
		"150097": "Itdida账户配置错误",
		"150098": "渠道不能既不支持商业地址也不支持住宅地址",
		"150099": "运单未找到",
		"150100": "维沃签名异常",
		"150101": "维沃数据异常",
		"150102": "17签名异常",
		"150103": "17数据异常",
		"150104": "佣金比例不能小于0",
		"150105": "折扣必须大于0%和小于等于1000%",
		"150106": "方案已经被配置",
		"150107": "标签生成失败",
		"150108": "数据处理中",
		"150109": "API接口处理中",
		"150110": "API方案处理中",
		"150111": "禁运配置处理中",
		"150112": "限运配置处理中",
		"150113": "极兔快递MX只支持墨西哥",
		"150114": "极兔快递区县名称必须",
		"150115": "极兔快递的允许重量为10G到30000G 即0.01KG到30KG",
		"150116": "Accufrate获取费用失败",
		"150117": "计算方式错误",
		"150118": "接口账户必填",
		"150119": "省份转换关系不存在",
		"150120": "省份已经存在",
		"150121": "省份Code已经存在",
		"150122": "track123 数据异常",
		"150123": "track123 签名异常",
		"150124": "签收费类型重复",
		"150125": "当没有签名费配置时，不能启用签名签收费",
		"150126": "易可美快递US只支持美国",
		"160000": "合作伙伴分组名称已存在",
		"160001": "合作伙伴编号已存在",
		"160002": "未找到相应的运输方案",
		"160003": "分组信息不存在",
		"160004": "合作伙伴未找到",
		"160005": "合作伙伴佣金未找到",
		"160006": "分组佣金未找到",
		"160007": "佣金记录已添加，不可重复添加",
		"160008": "合作伙伴标签值已经存在",
		"160009": "没有佣金比例配置",
		"160010": "已没有未结算的佣金",
		"160011": "结算金额必须大于0",
		"170001": "应用不存在",
		"170002": "无效的应用密匙",
		"170003": "应用没有获得店铺授权",
		"170004": "应用没有获得店铺该api授权",
		"170005": "应用还未设置所需权限",
		"170006": "授权项标识已经存在",
		"170007": "授权项名称已经存在",
		"170008": "授权项必填",
		"170009": "授权项不存在",
		"170010": "申请记录已审核",
		"170011": "授权项不在授权应用中",
		"170012": "应用授权签名不正确",
		"170013": "无效的返回地址",
		"900000": "产品数量必填",
		"900001": "配送订单第三面单必填",
		"900002": "配送订单第三方面单文件必传",
		"900003": "文件格式不正确",
		"900004": "配送订单赛易物流必填",
		"900005": "配送订单派送方式必填",
		"900006": "赛易物流vatcode必填",
		"900007": "仓库不存在",
		"900008": "产品信息不存在",
		"900009": "仓库库存不足",
		"900010": "配送订单包装材料必填",
		"900011": "配送订单包装材料不存在",
		"900012": "包装材料体积或容重不足",
		"900013": "国家不存在",
		"900014": "省份不存在",
		"900015": "城市不存在",
		"900016": "区县不存在",
		"900017": "仓库/地址不支持赛易物流",
		"900018": "暂不支持赛易物流",
		"900019": "付款异常",
		"900020": "余额不足",
		"900021": "透支额度不足",
		"900022": "不可重复支付",
		"900023": "不可重复退款",
		"900024": "退款失败",
		"900025": "公司不存在",
		"900026": "逾期未付",
		"900027": "配送订单不存在",
		"900028": "配送订单状态已改变",
		"900029": "授权应用不存在",
		"900030": "无效的应用密匙",
		"900031": "公司已经被冻结",
		"900032": "应用没有获得公司授权",
		"900033": "配送订单状态已取消",
		"900034": "订单id或订单号必填",
		"900035": "产品必填",
		"900036": "配送订单费用未设定",
		"900037": "应用已经被关闭",
		"900038": "产品名称已存在",
		"900039": "产品货号已存在",
		"900040": "运输特征必传",
		"900041": "产品货号不符合规则",
		"900042": "传入的标签识别码重复",
		"900043": "标签图片不可空",
		"900044": "物流类别错误",
		"900045": "派送方式错误",
		"900046": "上传图片失败",
		"900047": "标签图片法解析条码失败或格式错误",
		"900048": "标签不存在",
		"900049": "默认标签不可删除",
		"900050": "申报国家海关信息不完整",
		"900051": "当前国家不存在或未开通",
		"900052": "当前币种不存在或未开通",
		"900053": "包装袋仅支持一个产品",
		"900054": "包含电池电池信息必填",
		"900055": "存在非法运输特征",
		"900056": "电池成分不存在",
		"900057": "电池类型不存在",
		"900058": "非法标签Id",
		"900059": "输入的信息不完整",
		"900060": "发自地址或者收货地址除address2 和 区县英文名称外其他是必须的",
		"900061": "不支持的枚举类型",
		"900062": "数据处理中",
		"900063": "状态不允许操作",
		"900064": "异常状态与客户操作类型不匹配",
		"900065": "超过最大数量",
		"900066": "计划暂不支持",
		"900067": "正则不符合规则",
		"900068": "同一单只允许一种货币，海关申报只能有一种货币报价",
		"900069": "地址的行政上一级不可空",
		"900070": "上门揽件不支持",
		"900071": "包含电池，电池信息必填",
		"900072": "当前包裹已销毁",
		"900073": "卡车报价已过期",
		"900075": "当报价类型为WG时服务等级必须的",
		"900078": "当前退货计划产品FNSKU不可重复",
		"900079": "退货包裹编号已存在",
		"900080": "邮编不能为空",
		"900081": "详细地址不能为空",
		"900082": "当前产品已被使用不允许删除",
		"900083": "已申请退货"
	}
};
